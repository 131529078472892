import React from 'react';
import './NewSection.css'; 
import logo from "../images/logo.png"; 
import buy from "../images/buy.png"; 

const NewSection = () => {
  return (
    <section className="new-section">
      <div className="content">
        <div className="text">
          <h2>The secret plan</h2>
          <p>- Top Trending</p>
          <p>- Dexscreener verification</p>
          <p>- Twitter push</p>
          <a href="https://raydium.io/" target='_blank' rel="noopener noreferrer" className="image-link">
            <img src={buy} alt="Enlace" />
          </a>
        </div>
        <div className="image">
          <img src={logo} alt="Example" />
        </div>
      </div>
    </section>
  );
};

export default NewSection;
