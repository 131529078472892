import React from 'react';
import './FirstSection.css';

const FirstSection = () => {
  return (
    <section className="first-section">
      <div className="content">
        <div className="image">
          <div className='image-container'/>
        </div>
        <div className="text">
          <h2>North Korea Digital Currency</h2>
          <p>You're not a north korean citizen <br></br><br></br> we have traced your IP
          We have sent the nuclear missiles. </p>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
