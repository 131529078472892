import './App.css';
import Header from "./components/Header"
import FirstSection from './components/FirstSection.jsx';
import AboutSection from './components/AboutSection.jsx';
import NewSection from './components/NewSection.jsx';
import Footer from './components/Footer.jsx'

function App() {
  return (
    <div className="App">
      <Header />
      <div className="firstSection">
        <FirstSection />
        <AboutSection />
        <NewSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;